<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t("ServiceReview") }}
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            dense
            outlined
            :label="$t('search')"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        hide-default-footer
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.servingreview_id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.servingreview_image`]="{ item }">
          <v-img
            class="rounded-lg elevation-3 my-2"
            :src="item.servingreview_image"
            width="50"
            height="50"
          ></v-img>
        </template>
        <template v-slot:[`item.customer_fname`]="{ item }">
          {{ item.customer_prefix }} {{ item.customer_fname }}
          {{ item.customer_lname }}
        </template>
        <template v-slot:[`item.online_fullname`]="{ item }">
          {{ item.servingreview_comment }} {{ item.customer_prefix }}
          {{ item.customer_fname }} {{ item.customer_lname }}
        </template>
        <template v-slot:[`item.servingreview_rating`]="{ item }">
          <v-rating
            :value="+item.servingreview_rating"
            color="warning"
            readonly
            background-color="warning"
            dense
            small
            class="me-3 flex-shrink-0"
          ></v-rating>
        </template>
        <template v-slot:[`item.servingreview_user`]="{ item }">
          <v-rating
            :value="+item.servingreview_user"
            color="warning"
            background-color="warning"
            dense
            small
            readonly
            class="me-3 flex-shrink-0"
          ></v-rating>
        </template>
        <template v-slot:[`item.servingreview_doctor`]="{ item }">
          <v-rating
            readonly
            :value="+item.servingreview_doctor"
            color="warning"
            small
            background-color="warning"
            dense
            class="me-3 flex-shrink-0"
          ></v-rating>
        </template>
        <template v-slot:[`item.servingreview_shop`]="{ item }">
          <v-rating
            :value="+item.servingreview_shop"
            readonly
            small
            color="warning"
            background-color="warning"
            dense
            class="me-3 flex-shrink-0"
          ></v-rating>
        </template>
        <template v-slot:[`item.servingreview_status_id`]="{ item }">
          <StatusBlock :status="item.servingreview_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="message=''; commentId=item.servingreview_id;isComment = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiCommentEditOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("reply_comments") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="statusId = item.servingreview_status_id;commentId = item.servingreview_id; isUpdateStatus = true"
                v-on="on"
              >
                <v-icon>{{ item.servingreview_status_id == '1'? icons.mdiDeleteOutline : icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.servingreview_status_id == '1'? $t("suspend"): $t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <v-dialog
      v-model="isComment"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('reply_comments') }}
        </v-card-title>
        <v-form
          ref="formRepy"
          @submit.prevent="repyCommentSubmit"
        >
          <v-card-text>
            <v-textarea
              v-model="message"
              :label="$t('message')"
              :rules="[required]"
              outlined
              dense
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loadingComment"
              :disabled="loadingComment"
              @click="confirmRepyComment"
            >
              {{ $t('confirm') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="isComment = false"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>   {{ statusId == '1'? $t('confirmSuspendReviewAfterService') : $t('cancelSuspendReviewAfterService') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loadingUpdate"
            :disabled="loadingUpdate"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiCommentEditOutline, mdiDeleteOutline, mdiCheck, mdiAlertOutline,
} from '@mdi/js'
import useReviewAfterService from './useReviewAfterService'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    Pagination,
    StatusBlock,
  },

  setup() {
    return {
      ...useReviewAfterService(),
      icons: {
        mdiCommentEditOutline,
        mdiDeleteOutline,
        mdiCheck,
        mdiAlertOutline,
      },
    }
  },

}
</script>
