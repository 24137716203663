import { required } from '@/@core/utils/validation'
import reviewAfterService from '@/api/WebsiteManageMent/reviewAfterService'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useReviewAfterService() {
  const rating = ref(3)
  const searchtext = ref('')
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'center',
      value: 'servingreview_id',
      width: 10,
    },
    {
      text: i18n.t('service_number'),
      value: 'serve_code',
      width: 120,
    },
    {
      text: i18n.t('course_name'),
      value: 'course_name',
      width: 250,
    },
    {
      text: i18n.t('use_service'),
      value: 'customer_fname',
      width: 220,
    },
    { text: i18n.t('image'), value: 'servingreview_image', align: 'center' },
    { text: i18n.t('by'), value: 'online_fullname', width: 220 },
    {
      text: i18n.t('reply_by'),
      value: 'servingreview_reply',
      width: 150,
    },
    {
      text: i18n.t('rating_all'),
      value: 'servingreview_rating',
      align: 'center',
      width: 200,
    },
    {
      text: i18n.t('rating_user'),
      value: 'servingreview_user',
      align: 'center',
      width: 200,
    },
    {
      text: i18n.t('rating_doctor'),
      value: 'servingreview_doctor',
      align: 'center',
      width: 200,
    },
    {
      text: i18n.t('rating_shop'),
      value: 'servingreview_shop',
      align: 'center',
      width: 220,
    },
    {
      text: i18n.t('status'),
      value: 'servingreview_status_id',
      align: 'center',
      width: 220,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center fixed',
      width: 120,
    },
  ])

  const formRepy = ref(null)
  const isComment = ref(false)
  const commentId = ref('')
  const message = ref('')
  const loadingComment = ref(false)

  const isUpdateStatus = ref(false)
  const statusId = ref('')
  const loadingUpdate = ref(false)

  const fetchDataTable = () => {
    loading.value = true
    reviewAfterService
      .reviewAfterServiceList({
        searchtext: searchtext.value,
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      })
      .then(res => {
        const { data, count, segment, count_of_page } = res
        dataTableList.value = data
        totalPage.value = count_of_page
        totalDataTableList.value = count
        segmentId.value = segment
        loading.value = false
      })
  }

  const confirmRepyComment = () => {
    const isFormValid = formRepy.value.validate()
    if (!isFormValid) return
    loadingComment.value = true
    reviewAfterService
      .reviewAfterComment({
        servingreview_id: commentId.value,
        servingreview_reply: message.value,
      })
      .then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        fetchDataTable()
        isComment.value = false
        loadingComment.value = false
      })
  }

  const updateStatus = () => {
    loadingUpdate.value = true
    reviewAfterService
      .reviewAfterStatusUpdate({
        servingreview_id: commentId.value,
        servingreview_status_id: statusId.value == 1 ? 2 : 1,
      })
      .then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loadingUpdate.value = false
        fetchDataTable()
        isUpdateStatus.value = false
      })
  }

  watch([searchtext, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    searchtext,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    loading,
    required,
    fetchDataTable,
    confirmRepyComment,
    totalPage,
    commentId,
    isComment,
    message,
    formRepy,
    columns,
    footer,
    rating,
    loadingComment,

    isUpdateStatus,
    loadingUpdate,
    statusId,
    updateStatus,
  }
}
