import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const reviewAfterServiceList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'servingreview/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('get review after service list err : ', err)

      return {}
    })

  return response
}
const reviewAfterComment = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'servingreview',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('add comment err : ', err)

      return {}
    })

  return response
}
const reviewAfterStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'servingreview/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update status review err : ', err)

      return {}
    })

  return response
}
export default { reviewAfterServiceList, reviewAfterComment, reviewAfterStatusUpdate }
